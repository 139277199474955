.SideBarItemWrapper {
  box-sizing: content-box;
  padding: 10px;
  padding-left: 20px;
  border-bottom: 1px solid black;
  display: flex;
}

.title {
  box-sizing: content-box;
  font-size: 14px;
  padding: 2px;
}

.SideBarItemWrapper:hover {
  background-color: rgb(255, 247, 247);
  cursor: pointer;
}

.NavLink {
  text-decoration: none;
  color: black;
  font-weight: 600;
  width: 100%;
}
