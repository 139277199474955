.App {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 600px) {
  .App {
    flex-direction: column;
  }
}
