.SideBar {
  background-color: rgb(238, 245, 253);
  border: 0.1px solid grey;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  min-width: 350px;
}

.ToggleCopyButton {
  display: none;
}

@media screen and (max-width: 600px) {
  .visible {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .invisible {
    display: none;
  }

  .ToggleCopyButton {
    background: none;
    color: inherit;
    border: none;
    padding-right: 10px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: inline-block;
  }

  .spanButton {
    width: 100%;
    text-align: right;
    background-color: aliceblue;
    padding: 4px;
  }
}

@media screen and (min-width: 600px) {
  .spanButton {
    height: inherit !important;
  }
}
