.SideBarItemWrapper {
  box-sizing: content-box;
  padding: 10px;
  border-bottom: 0.1px solid rgb(71, 70, 70);
  display: flex;
  padding-left: 50px;
}

.title {
  box-sizing: content-box;
  font-size: 12px;
}

.SideBarItemWrapper:hover {
  background-color: rgb(255, 247, 247);
  cursor: pointer;
}

.NavLink {
  text-decoration: none;
  color: black;
  font-weight: 400;
}
